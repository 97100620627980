import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  search (apiFilter, filter, search) {
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('nombre', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.principal.value) {
      apiFilter.addExact(filter.principal.field, filter.principal.value)
    }
  },
  async selectRepresentante (Vue, filter, search, sorter, page, idcliente, idclientePotencial) {
    const apiFilter = new APIFilter()
    if (idclientePotencial) {
      apiFilter.addExact('idcliente_potencial', idclientePotencial)
    } else {
      apiFilter.addExact('idcliente', idcliente)
    }
    apiFilter.addGT('estado', 0)
    this.search(apiFilter, filter, search)
    const resp = await Vue.$api.call(
      'representante.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectRepresentanteRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idrepresentante', pks)
    const resp = await Vue.$api.call('representante.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deleteRepresentante (Vue, idrepresentante) {
    await Vue.$api.call('representante.delete', { idrepresentante: idrepresentante })
  },
}
